.root {
    width: 100%;
    max-width: 1377px;
    margin: 0;
    padding: 20px;
    padding-bottom: 10px;
    font-family: "Roboto", sans-serif;
    color: var(--black-base);
}

.h2 {
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
}

.h3 {
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
}

.form {
    margin-top: 22px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 22px;
    grid-column-gap: 30px;
    align-items: flex-start;
}

.inputText {
    padding: 0 25px;
    padding-right: 40px;
    margin: 0;
    margin-top: 14px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: inherit;
}

.text {
    margin-top: 34px;
    padding: 0;
    font-family: inherit;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: inherit;
    text-align: end;
}

.text span {
    color: var(--secondary-400-base);
}

@media (min-width: 660px) {
    .root {
        max-width: 904px;
        margin-left: auto;
        margin-right: auto;

        padding: 20px 23px;
    }
    .form {
        margin-top: 62px;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 31px;
    }

    .inputText {
        padding: 0;
        padding-right: 10px;
    }

    .h2 {
        font-size: 36px;
        line-height: 0.67;
    }

    .text {
        font-size: 14px;
        font-weight: 300;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .root {
        padding: 0;
        max-width: 858px;
    }
    .h2 {
        font-size: 36px;
        line-height: 0.67;
    }
    .form {
        margin-top: 81px;
        grid-row-gap: 33px;
    }

    .inputText {
        padding: 0;
        padding-right: 10px;
    }

    .text {
        margin-top: 79px;
        margin-bottom: 19px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
