.root {
    width: 100%;
    padding: 0;
    display: block;
}

.wrapper {
    display: flex;
    justify-self: start;
    align-items: center;
    margin-top: 18px;
}

.wrapper label:first-child {
    margin-right: 8px;
}

.radio {
    display: flex;
    justify-self: start;
    align-items: center;
}

.text {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: normal;
    color: var(--black-base);
    margin-right: 12px;
    text-align: end;
}

.required {
    color: var(--error-400-base);
    font-weight: 700;
}

.errorWrapper {
    width: 100%;
    background-color: var(--error-400-base);
    padding: 6px 14px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
}

.errorText {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
}

@media (min-width: 660px) {
    .text {
        margin-right: 28px;
    }

    .wrapper {
        margin: 0;
        display: flex;
        justify-content: flex-end;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .wrapper {
        margin: 0;
        display: flex;
        justify-content: flex-end;
    }

    .text {
        margin-right: 46px;
        max-width: 230px;
        line-height: 1.2;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}

.error {
    margin-top: 3px;
    color: #df1642;
    margin-bottom: 5px;
}
