.root {
    width: 100%;
    max-width: 1377px;
    margin: 0;
    padding: 20px 0;
    font-family: "Roboto", sans-serif;
    color: var(--black-base);
}

.h2 {
    padding: 0;
    margin: 0 20px;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
    text-transform: uppercase;
}

.h3 {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--tertiary-600);
    text-transform: uppercase;
}

.form {
    margin-top: 20px;
    padding: 0 20px;
    padding-bottom: 31px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    align-items: flex-start;
    border-bottom: solid 1px var(--black-300);
}

.form:last-child {
    border-bottom: none;
}

@media (min-width: 660px) {
    .root {
        max-width: 920px;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0 auto;
    }

    .h2 {
        font-size: 36px;
        line-height: 0.67;
        margin: 0;
    }

    .text {
        font-family: inherit;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: inherit;
        padding: 0;
        margin: 0;
    }
    .firstLine {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: 18px;
        align-items: center;
        justify-content: end;
        border: none;
        padding: 0;
        align-items: flex-end;
        margin-top: 26px;
    }

    .form {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: 18px;
        align-items: center;
        justify-content: end;
        border: none;
        padding: 0;
        margin-top: 18px;
    }
    .form:nth-child(2) {
        margin-top: 11px;
    }

    .label {
        grid-template-areas:
            "wrapper wrapper"
            "errorWrapper errorWrapper";
    }

    .label > p {
        display: none;
    }
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .root {
        padding: 0;
        max-width: 858px;
    }
    .h2 {
        font-size: 36px;
        line-height: 0.67;
    }
    .h3 {
        margin-top: 22px;
        font-size: 18px;
        line-height: 1.33;
    }

    .form {
        margin-top: 10px;
        grid-row-gap: 16px;
        grid-column-gap: 13px;
    }

    .firstLine {
        margin-top: 10px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .hidden {
        display: none;
    }

    .text {
        display: none;
    }

    .firstLine {
        display: none;
    }
}
